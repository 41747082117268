<template>
  <base-form-item :field="field">
    <div class="fieldUpload--container">
      <ul
          class="fieldUpload--list el-upload-list__item"
          v-if="fileLists.length"
      >
        <li
            :key="item.id"
            class="position-relative"
            v-for="item in fileLists"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span @click="onPreview(item)">{{ item.nameWithSize }}</span>
            <i
                @click="downloadFile(item)"
                class="mr-2"
                slot="prefix"
                v-if="checkIfImage(item.name)"
            >
              <font-awesome-icon icon="arrow-circle-down" />
            </i>
          </div>
          <transition name="el-fade-in">
            <el-progress
                :percentage="downloadProgressPercent"
                :show-text="false"
                :stroke-width="3"
                status="success"
                v-if="item.downloading"
            ></el-progress>
          </transition>
        </li>
      </ul>
      <div v-else>
        <span class="no-date-title p-0">{{ $t('system.no_data') }}</span>
      </div>
      <!--      <LightBox :media="galleries"-->
      <!--                ref="lightbox"-->
      <!--                :showCaption="false"-->
      <!--                :showThumbs="false"-->
      <!--                :showLightBox="false"/>-->
      <LightBox
          :index="index"
          :items="galleries"
          :slideshow="false"
          @close="index = null"
          srcThumb="src"
          thumbsPosition="bottom"
          useZoomBar
      />
    </div>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import gallery from './gallery';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  name: 'fieldFileIndex',
  mixins: [abstractForm, gallery],
  components: {
    BaseFormItem,
    LightBox: () => import('vue-cool-lightbox'),
  },
  data() {
    return {};
  },
  beforeMount() {
    this.setData(this.value);
  },
  methods: {},
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.fieldUpload--container {
  .fieldUpload--list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      span, i {
        cursor: pointer;
      }
    }

    li:hover {
      border-radius: 10px;
      background: #eef1f4;
    }
  }
}
</style>
